import useSWR from 'swr';
import axios from 'axios';
import ReactWordcloud from 'react-wordcloud';
import { useEffect, useState } from 'react';

const fetcher = url => axios.get(url).then(res => res.data);

export default function AIWordCloud() {
  const { data, isLoading } = useSWR('https://tf-embeddables-api.yogurtsyum.workers.dev/aiwordcloud/data', fetcher);
  const [words, setWords] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const submitWord = (e) => {
    e.preventDefault();
    const word = e.target.word.value;
    setWords(data.map((d) => {
      if (d.word === word) {
        return { ...d, freq: d.freq + 1 };
      }
      return d;
    }));
    axios('https://tf-embeddables-api.yogurtsyum.workers.dev/aiwordcloud/data', {
      method: 'POST',
      data: {
        word
      }
    });
    setSubmitted(true);
  }

  useEffect(() => {
    setWords(data);
  }, [data]);

  if (isLoading) return (
    <>
      <p>Loading...</p>
    </>
  );

  return (
    <>
      <div id="ai-word-cloud">
        <div className="container">
          <ReactWordcloud
            words={(words || []).map((d) => ({ text: d.word, value: d.freq }))}
            options={{
              fontSizes: [20, 75],
            }}
          />
        </div>
        <form onSubmit={submitWord}>
          {
            !submitted
              ? <>
                <input name="word" placeholder="Add your own word..." />
                <button type="submit">Submit</button>
              </>
              : <>
                <p className="success-text-group">
                  <strong className="success-text">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="success-svg">
                      <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
                    </svg>
                    Your word has been submitted!
                  </strong> If it is new, it will only show up once an editor approves it.
                </p>
              </>
          }
        </form>
      </div>
    </>
  );
}