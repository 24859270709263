import { useEffect, useState } from 'react';
import clsx from 'clsx';

export default function Wordle() {
  const [guesses, setGuesses] = useState(() => {
    const savedGuesses = localStorage.getItem('guesses');
    return savedGuesses ? JSON.parse(savedGuesses) : [];
  });
  const [draft, setDraft] = useState('');
  const [dictionary, setDictionary] = useState([]);
  const [hasWon, setHasWon] = useState(() => {
    const savedHasWon = localStorage.getItem('hasWon');
    return savedHasWon ? JSON.parse(savedHasWon) : false;
  });
  const answer = 'CHECK';

  const submitGuess = () => {
    if (draft.length !== 5) {
      alert('Word must be 5 letters');
      return;
    }

    if (!dictionary.includes(draft.toLowerCase())) {
      alert('Invalid word');
      return;
    }

    const newGuesses = [...guesses, draft.split('')];
    setDraft('');
    setGuesses(newGuesses);
    localStorage.setItem('guesses', JSON.stringify(newGuesses));

    if (draft === answer) {
      setHasWon(true);
      localStorage.setItem('hasWon', JSON.stringify(true));
    }
  }

  useEffect(() => {
    fetch('https://raw.githubusercontent.com/dwyl/english-words/refs/heads/master/words.txt', {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
      }
    })
      .then((response) => response.text())
      .then((data) => {
        setDictionary(data.split('\n'));
      });

    const handleKeyPress = (e) => {
      if (hasWon) return;

      if (e.key === 'Enter') {
        submitGuess();
      } else if (e.key === 'Backspace') {
        setDraft((prevDraft) => prevDraft.slice(0, -1));
      } else if (/^[a-zA-Z]$/.test(e.key) && draft.length < 5) {
        setDraft((prevDraft) => prevDraft + e.key.toUpperCase());
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [draft, guesses]);

  return (
    <>
      <div id="wordle">
        <div>
          {/* <h1><em>Forum</em> Wordle</h1>
          <p>January 17, 2024</p> */}

          <table>
            {
              [1, 2, 3, 4, 5, 6].map((row) => (
                <tr key={row}>
                  {
                    guesses.length === row - 1
                      ? [1, 2, 3, 4, 5].map((col) => (
                        <td key={col}>
                          {draft[col - 1]}
                        </td>
                      ))
                      : [1, 2, 3, 4, 5].map((col) => {
                        if (guesses.length >= row) {
                          return <td key={col} className={clsx(
                            guesses[row - 1][col - 1] === answer[col - 1] ? 'correct' : (
                              answer.includes(guesses[row - 1][col - 1]) ? 'wrong-position' : 'incorrect'
                            )
                          )}>
                            {guesses[row - 1][col - 1]}
                          </td>
                        } else {
                          return <td key={col} />
                        }
                      }
                      )}
                </tr>
              ))
            }
          </table>

          <div class="keyboard">
            <div class="row">
              {['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'].map((letter) => (
                <button key={letter} disabled={hasWon} onClick={() => setDraft((prevDraft) => prevDraft + letter)}>{letter}</button>
              ))}
              <button disabled={hasWon} onClick={() => setDraft((prevDraft) => prevDraft.slice(0, -1))}>⌫</button>
            </div>
            <div class="row">
              {['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'].map((letter) => (
                <button key={letter} disabled={hasWon} onClick={() => setDraft((prevDraft) => prevDraft + letter)}>{letter}</button>
              ))}
            </div>
            <div class="row">
              {['Z', 'X', 'C', 'V', 'B', 'N', 'M'].map((letter) => (
                <button key={letter} disabled={hasWon} onClick={() => setDraft((prevDraft) => prevDraft + letter)}>{letter}</button>
              ))}
            </div>
            <div class="row">
              <button disabled={hasWon} onClick={submitGuess}>Submit</button>
            </div>
          </div>
        </div>

        {hasWon && <div>
          <h1>🏆<br />Good job!</h1>
          <p>Today's word is from:</p>
          <a target="_parent" href="https://readtheforum.org/24893/op/freshman-check-in-midterms-stress-who-can-support-you/" class="story-link">
            <div class="story-container">
              <img src="https://readtheforum.org/wp-content/uploads/2024/12/wtaJyBAz6qb41ZZXs4IxEmY6vH8hk5o2HHIAHavq.jpg" class="story-image-cropped" />
              <div className="story-card">
                <h2>Freshman Check-in: Midterms, Stress, Who Can Support You?</h2>
                <p>Claire Noventa, Staff Writer • December 18, 2024</p>
              </div>
            </div>
          </a>
        </div>}

        {(guesses.length === 6 && !hasWon) && <div>
          <h1>❌<br />Maybe tomorrow...</h1>
          <p>Today's word is from:</p>
          <a target="_parent" href="https://readtheforum.org/24893/op/freshman-check-in-midterms-stress-who-can-support-you/" class="story-link">
            <div class="story-container">
              <img src="https://readtheforum.org/wp-content/uploads/2024/12/wtaJyBAz6qb41ZZXs4IxEmY6vH8hk5o2HHIAHavq.jpg" class="story-image-cropped" />
              <div className="story-card">
                <h2>Freshman Check-in: Midterms, Stress, Who Can Support You?</h2>
                <p>Claire Noventa, Staff Writer • December 18, 2024</p>
              </div>
            </div>

            {/* TODO: make the keybaord colored */}
          </a>
        </div>}
      </div>
    </>
  )
}